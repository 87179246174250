import { useEffect } from "react"

import { useMe } from "@supernovaio/cloud/hooks/data/useMe"
import { useWorkspace } from "@supernovaio/cloud/hooks/data/useWorkspace"
import { useCurrentMembership } from "@supernovaio/cloud/hooks/useCurrentMembership"
import { useParams } from "@supernovaio/cloud/hooks/useParams"

export function useRefiner() {
  const { data: me } = useMe()
  const { wsId } = useParams()
  const { data: workspace } = useWorkspace(wsId)
  const { membership } = useCurrentMembership({
    wsId,
  })

  useEffect(() => {
    const setProject = async () => {
      // we want to make sure refiner is imported on the client since it's a client side library
      const refiner = (await import("refiner-js")).default
      refiner("setProject", "3c62e130-44c1-11ee-90b4-8bb90e9f721d")
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setProject()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (me && workspace && membership) {
        // we want to make sure refiner is imported on the client since it's a client side library
        const refiner = (await import("refiner-js")).default

        refiner("identifyUser", {
          id: me.id,
          email: me.email,
          name: me.profile.name,
          isOnboardingFinished: me.profile.isOnboardingFinished,
          userRole: membership?.role,
          workspaceName: workspace?.profile.name,
          environment: "Supernova Next",
        })
      }
    })()
  }, [me, workspace, membership])

  return {
    // We don't need to do anything here since we only need to call the useRefiner
    init: () => {},
  }
}
