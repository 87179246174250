import { useFullStory } from "./useFullStory"
import { useRefiner } from "./useRefiner"

export function useSupernovaAnalytics() {
  const FS = useFullStory()
  const R = useRefiner()

  return {
    init: () => {
      FS.init()
      R.init()
    },
    identify: () => {
      FS.setIdentity()
    },
    track: FS.track,
    isInitialized: FS.isInitialized(),
  }
}
