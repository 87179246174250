import { env } from "@supernovaio/cloud/env.mjs"
import { useMe } from "@supernovaio/cloud/hooks/data/useMe"
import { useWorkspaceExistingUsers } from "@supernovaio/cloud/hooks/data/useWorkspaceExistingUsers"
import { useWorkspaceSubscription } from "@supernovaio/cloud/hooks/data/useWorkspaceSubscription"
import { useParams } from "@supernovaio/cloud/hooks/useParams"

import { init, FullStory, isInitialized } from "@fullstory/browser"

const orgId = env.NEXT_PUBLIC_FULLSTORY_ORG_ID

// Taken from @fullstory/browser/dist/index.d.ts object: V2OperationOptions field: [V2OperationName.SetIdentity]
type SetIdentityParams =
  | {
      anonymous?: boolean
      consent?: boolean
      uid?: string | false
      properties?: object
      schema?: object
    }
  | string
  | boolean
  | null
  | undefined

type TrackEventParams = {
  name: string
  properties: object
  schema?: object
}

// TODO: Move all initializations of analytics (Refiner and Highlight) into this hook
export function useFullStory() {
  const { data: user } = useMe()
  const { wsId } = useParams()

  const { data: workspaceSubscription } = useWorkspaceSubscription({
    workspaceId: wsId,
  })
  const { data: wsExistingUsers } = useWorkspaceExistingUsers({
    workspaceId: wsId,
  })

  const wsExistingUser = wsExistingUsers?.find((u) => u.user.id === user?.id)

  if (!user || !wsId || !wsExistingUser || !workspaceSubscription) {
    return {
      shutdown: () => null,
      restart: () => null,
      init: () => null,
      setIdentity: () => null,
      track: () => null,
      isInitialized: () => false,
    }
  }

  const uid = `${user.id}_${wsId}`
  const setIdentityParams: SetIdentityParams = {
    uid,
    properties: {
      // User related attributes
      userId: user.id,
      role: wsExistingUser.wsRawRole,
      displayName: user.displayName,
      email: user.email,
      registrationDate: user.createdAt,

      // Feature related attributes
      // TODO: I don't know how to do these now
      numberOfAssociatedDesignSystems: "",
      isMultiWorkspaceUser: "",
      isInvitedUser: "",

      // Onboarding related attributes
      onboardingPhase: user.profile.onboarding?.phase,
      companyName: user.profile.onboarding?.companyName,
      department: user.profile.onboarding?.department,
      jobTitle: user.profile.onboarding?.jobTitle,
      jobLevel: user.profile.onboarding?.jobLevel,
      numberOfPeopleInDesignTeam:
        user.profile.onboarding?.numberOfPeopleInDesignTeam,
      numberOfPeopleInOrg: user.profile.onboarding?.numberOfPeopleInOrg,

      // Workspace related attributes
      workspaceId: wsId,
      workspacePlan: workspaceSubscription.product,
      workspacePlanExpiration:
        workspaceSubscription.rawRemoteSubscription.currentPeriodEnd,
    },
  }

  return {
    shutdown: () => FullStory("shutdown"),
    restart: () => FullStory("restart"),
    init: () => {
      if (orgId) {
        init({
          orgId,
        })
      }
    },
    setIdentity: () => FullStory("setIdentity", setIdentityParams),
    track: (params: TrackEventParams) => FullStory("trackEvent", params),
    isInitialized,
  }
}
