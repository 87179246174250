"use client"

import { useEffect } from "react"

import { useImpersonation } from "@supernovaio/cloud/features/impersonation"
import { useMe } from "@supernovaio/cloud/hooks/data/useMe"
import {
  APP_ENVIRONMENT,
  AppEnvironments,
} from "@supernovaio/cloud/utils/environment"

import { useSupernovaAnalytics } from "./useSupernovaAnalytics"

export default function SupernovaAnalyticsInit() {
  const { data: me } = useMe()
  const analytics = useSupernovaAnalytics()
  const { isImpersonating } = useImpersonation()

  useEffect(() => {
    const init = () => {
      // Don't initialize analytics when impersonated, on localhost, or for internal emails
      if (
        isImpersonating ||
        APP_ENVIRONMENT === AppEnvironments.local ||
        me?.email?.includes("@supernova") ||
        me?.email?.includes("@sudolabs")
      )
        return

      // Because analytics libraries are client-only, we need to make sure window is defined
      // identifiedRef is used to make sure we identify the user only once
      if (typeof window !== "undefined" && me) {
        analytics.init()
      }

      if (analytics.isInitialized) {
        analytics.identify()
      }
    }

    init()
  }, [me, analytics, isImpersonating])

  return null
}
